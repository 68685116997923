/**
 * This is the main application setup code for Figured.
 *
 * THIS FILE SHOULD NOT CONTAIN ANY LOGIC. It should only be used to set-up third-party libraries
 * expose browser globals, and should not be imported by any other scripts.
 */

import "./jquery.js";
import _ from "lodash";
import $ from "jquery";
import Vue from "vue";
import VueX from "vuex";
import AsyncComputed from "vue-async-computed"
import locale from "element-ui/lib/locale/lang/en";
import element from "element-ui"
import VueTimeago from "vue-timeago";
import {VuePlugin} from "vuera"
import Clipboard from "clipboard";
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import highchartsMore from "highcharts/highcharts-more";
import bootbox from "bootbox";
import inflection from "inflection";

import OperationTrackers from "Figured/Assets/Components/OperationTrackers.vue"
import DisplayTree from "BusinessStructure/Assets/Components/DisplayTree.vue";
import WidgetSettings from "FarmHiddenWidgets/Assets/Components/WidgetSettings.vue";
import DashboardKpis from "DashboardCharts/Assets/Components/DashboardKpis.vue";
import ClosingCashPosition from "DashboardCharts/Assets/Components/ClosingCashPosition.vue";
import VarianceByCategory from "DashboardCharts/Assets/Components/VarianceByCategory.vue";
import DashboardOperatingExpenses from "DashboardCharts/Assets/Components/DashboardOperatingExpenses.vue";
import DashboardGrossProfitAndExpenses from "DashboardCharts/Assets/Components/DashboardGrossProfitAndExpenses.vue";
import LendingNotificationWidget from "Lending/Assets/Components/Widgets/LendingNotificationWidget.vue";
import DashboardAuditWidget from "Audit/Assets/Components/DashboardAuditWidget.vue";

import * as Utils from "Figured/Assets/Modules";
import {globals,sentry,lang,moment} from "Figured/Assets/Modules";
import GlobalMixin from "Figured/Assets/Mixins/global-mixin.js";

/**
 * @deprecated
 */
window.Utils = Utils;

/**
 * Lodash
 */
window._ = _;

/**
 * Vue
 */
Vue.config.debug = Vue.config.devtools = globals.debug();

window.Vue = Vue;

/**
 * VueX
 */

Vue.use(VueX);

window.VueX = VueX;

/**
 * Enable async computed properties
 */
Vue.use(AsyncComputed);

/**
 * Element UI
 */
Vue.use(window.ELEMENT = element, {locale});


/**
 * We are using an instance of Vue as a global "event bus".
 */
window.EventBus = new Vue();


/**
 * Mixin that will be used by ALL Vue components.
 */

Vue.mixin(GlobalMixin);


/**
 * https://github.com/egoist/vue-timeago
 */

Vue.use(VueTimeago, {
    name: "timeago",
    /* locales? */
});

/**
 * https://github.com/akxcv/vuera
 * https://x-team.com/blog/react-vue-component-integration/
 */
Vue.use(VuePlugin);


/**
 * Error Reporting: install sentry error logging.
 */

sentry.install(Vue);


/**
 * Localisation
 *
 * TODO: it would be great if this was not exposed on the window.
 */

window.lang = lang;


window.moment = moment;

/**
 * Highcharts
 */
exporting(Highcharts);
highchartsMore(Highcharts);
window.Highcharts = Highcharts;

/**
 * Global Vue, eg. for using Element UI components without a dedicated vue instance.
 *
 * TODO: This is not no good because every page top level view should be responsible its Vue instance.
 *       This is only used on the dashboard at the moment so should be easy to refactor. (2019-01-23)
 */
if (document.getElementById("global-vue-app")) {
    Vue.component("OperationTrackers", OperationTrackers);
    window.GlobalVue = new Vue({
        el: "#global-vue-app",
        store: new window.VueX.Store({}),
        components: {
            displaytree: DisplayTree,
            WidgetSettings,
            DashboardKpis,
            ClosingCashPosition,
            VarianceByCategory,
            DashboardOperatingExpenses,
            DashboardGrossProfitAndExpenses,
            LendingNotificationWidget,
            DashboardAuditWidget,
        },
    });
}

/**
 * Clipboard
 */

window.Clipboard = Clipboard;


/**
 * Bootbox - confirmation dialog?
 *
 * @deprecated We should use Element UI
 */
window.bootbox = bootbox;


/**
 * Inflection, such as pluralization.
 *
 * https://www.npmjs.com/package/inflection
 */
window.inflection = inflection;

import "bootstrap";
import "bootstrap-select/js/bootstrap-select.js";
import "ajax-bootstrap-select/dist/js/ajax-bootstrap-select.js";
import BootStrapTimePicker from "bootstrap-datetimepicker-npm/src/js/bootstrap-datetimepicker.js";
import "bootstrap-3-typeahead";

BootStrapTimePicker($);

/**
 * Fixed headers.
 *
 * http://mkoryak.github.io/floatThead/
 */
import "floatthead";

/**
 * Data Tables
 *
 * TODO: We should refactor this in favour of Element UI.
 */
import DataTable from "datatables.net";
import DataTableBs from "datatables.net-bs";
import yadcf from "yadcf";

DataTable(window, window.$);
DataTableBs(window, window.$);
yadcf(window, window.$);

/**
 * Fuel UX
 *
 * TODO: We should refactor this in favour of Element UI
 */
import "fuelux/dist/js/fuelux.js";
